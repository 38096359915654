const flash = document.querySelector(".atoms-flash_message");

document.body.addEventListener("showFlash", function (e) {
  const flashKeys = ["notice", "alert"];
  const messages = flashKeys.map((key) => {
    if (key in e.detail) {
      return `
        <div class="item ${key}">
          <div class="close">&#10005;</div>
          <div class="message">${decodeURIComponent(e.detail[key])}</div>
        </div>
      `;
    }
  })

  flash.innerHTML = messages.join("");
});

flash.addEventListener("click", function (e) {
  if (e.target.matches(".close")) {
    const item = e.target.closest(".item");

    item.classList.add("fadeout");
    item.addEventListener("animationend", function () { item.remove() });
  }
});