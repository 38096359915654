// Usage: import { addComponentEventHandlers } from 'application_view_component';

/**
 * 初回ロード時およびDOM更新時にイベントハンドラを設定する関数
 * @param {string} selector - The selector string of the target element.
 * @param {(element: Element) => void} callback - The callback function to be called when the event occurs.
 * @returns {void} Void.
 * @example
 * addComponentEventHandlers('.target', (element) => {
 *   element.addEventListener('click', (event) => {
 *     console.log('Clicked');
 *   });
 * });
 */
const addComponentEventHandlers = (selector, callback) => {
  // セレクタが空の場合は処理を終了
  if (!selector) { return; }

  // 初回ロード時にイベントハンドラを設定
  document.querySelectorAll(selector).forEach((element) => {
    callback(element);
  });

  // DOM 更新時にイベントハンドラを再設定
  // コールバック関数に結びつけられたオブザーバーのインスタンスを生成
  const observer = new MutationObserver((mutationList, _observer) => {
    // 追加されたノードに対してイベントハンドラを設定
    mutationList.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === 1) {
          node.querySelectorAll(selector).forEach((element) => {
            callback(element);
          });
        }
      });
    });
  });
  // 対象ノードの設定された変更の監視を開始
  observer.observe(document.body, { attributes: true, childList: true, subtree: true });
}

export { addComponentEventHandlers };
